<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
    fullscreen
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="accent" dark>
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.edit') }}
            Video
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Categoría
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Categoría"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.video_category.id"
                        placeholder="Categoría"
                        :items="videoCategories"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Nombre
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Nombre"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.name"
                        placeholder="Nombre"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Video de entrenamiento
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Video de entrenamiento"
                      rules=""
                    >
                      <v-file-input
                        label="Video de entrenamiento"
                        accept="video/mp4"
                        filled
                        show-size
                        prepend-icon="mdi-file-video"
                        @change="videoSelected"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.video">
                <v-list-item-content>
                  <v-list-item-title>
                    Video Actual
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-btn
                      :href="videoURL(item.video.url)"
                      target="_blank"
                    >
                      {{ item.video.name }} - {{ item.video.mime }} - {{ (item.video.size / 1024).toFixed(2) }} MB
                    </v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>

    <weeks-add></weeks-add>
    <weeks-edit />
    <week-delete></week-delete>
  </v-dialog>
</template>

<script>
  import customFilter from '@/plugins/customFilter'

  export default {
    name: 'TrainingEdit',
    mixins: [customFilter],
    components: {
      WeeksAdd: () => import("../weeks/WeekAddAlert"),
      WeeksEdit: () => import("../weeks/WeekEdit"),
      WeekDelete: () => import("../weeks/WeekDelete"),
    },
    data () {
      return {
        dialog: false,
        item: {
          video_category: {
            id: 0
          },
          video: false
        },
        planes: [],
        metas: [],
        usuarios: [],
        weekModel: {
          days: [],
          name: ""
        },
        weeksHeaders: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: this.$t('app.headers.name'),
            value: 'name',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        videoCategories: [],
        video: null
      }
    },
    mounted () {
      EventBus.$on('videos-edit', async (item) => {
        this.toggleLoader()
        this.videoCategories = await this.getVideoCategories()
        this.item   = item
        this.item.video_category = this.item.video_category ?? {id:0}
        this.dialog = true
        this.toggleLoader()
      })
    },
    methods: {
      videoURL(url) {
        return strapiAsset(url)
      },

      videoSelected(file) {
        this.video = file
      },

      async getVideoCategories() {
        let data = []
        await this.$http.get(route("video-categories"))
          .then(response => {
            if (response.body.data) {
              data = response.body.data
            } else {
              this.processError(response.body)
            }
          }, error => this.processError(error))
        return data
      },

      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            if (!this.video && !this.item.video) {
              this.processError("Se requiere un archivo compatible")
              return
            }

            this.toggleLoader()

            if (this.video) {
              let form = new FormData()
              form.append("files", this.video)

              await this.$http.post(route('upload'), form, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then(response => {
                if (response.body && Array.isArray(response.body) && response.body.length > 0) {
                  this.item.video = response.body[0]
                  this.video = false
                }
              })
            }

            await this.$http.put(route(`videos/${this.item.id}`), {
              data: this.item
            })
              .then(response => {
                if (response.body.data) {
                  //this.processSuccess(response)
                  this.dialog = false
                  EventBus.$emit('reload-items')
                } else {
                  this.processError(response)
                }
              }, error => {
                this.processError(error)
              })

            this.toggleLoader()
          })
      },
    },
  }
</script>

<style scoped>

</style>
